import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import '../styles/Header.css'
import logo from '../assets/logo.png'
import { getUser } from '../utils/utils.js'


function Header() {
  const [menuClick, setMenuClick] = useState(false)

  return (
    <nav className="navBar">
        <a href="http://www.cmc-engineering.fr/"><img src={logo} alt="logo" className="navBar-logo" /></a>
        <div className="menu-icon" onClick={() => setMenuClick(!menuClick)}>
          <i className={menuClick ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={menuClick ? 'nav-menu active' : 'nav-menu'}>
            <li><NavLink style={({ isActive }) => isActive ? { backgroundColor: `#6d76f7`, borderRadius: `4px`} : {}} className='nav-links' to="/technicien/mes-tickets">Mes tickets</NavLink></li>
            <li><NavLink style={({ isActive }) => isActive ? { backgroundColor: `#6d76f7`, borderRadius: `4px`} : {}} className='nav-links' to="/technicien/liste-tickets">Liste des tickets</NavLink></li>
            <li><NavLink style={({ isActive }) => isActive ? { backgroundColor: `#6d76f7`, borderRadius: `4px`} : {}} className='nav-links' to="/technicien/creation-ticket">Créer un ticket</NavLink></li>
            <li><NavLink style={({ isActive }) => isActive ? { backgroundColor: `#6d76f7`, borderRadius: `4px`} : {}} className='nav-links' to="/technicien/mes-informations"><i className="fa-solid fa-circle-user"></i>{getUser()}</NavLink></li>
        </ul>
    </nav>
  )
}

export default Header
