import React from 'react'

export default function InputRadio({labelName, errors, field, values, tabElem, handleChange}) {
  return (
    <div className="input-container">
    <p>{labelName} </p>
    {tabElem.map((item, i) => {
       return <label key={`${item['val']}-${i}`} className="radio">
                 <input 
                     className={`${errors[field] && 'error'}`}
                     type="radio" 
                     name={field}
                     onChange={handleChange}
                     checked={values[field] === item['val']}
                     value={item['val']}
                 />
                <span>{item['labelVal']}</span>
               </label>
    })}
    </div>

  )
}
