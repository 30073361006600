import { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom' 
import '../styles/Register.css'

import axios from '../api/axios'
import HeaderDetail from './HeaderDetail'
const REGISTER_URL = '/reldefSrv/resources/register'

const Register = () => {
  const [ civilite, setCivilite ] = useState('')
  const [ nom, setNom ] = useState('')
  const [ prenom, setPrenom ] = useState('')
  const [ courriel, setCourriel ] = useState('')
  const [ telephone, setTelephone ] = useState('')
  const [ adresse, setAdresse ] = useState('')
  const [ ville, setVille ] = useState('')
  const [ codePostal, setCodePostal ] = useState('')

  const nomRef = useRef()
  const errRef = useRef()

  const [errMsg, setErrMsg] = useState('')

  //Met le curseur directement sur login
  useEffect(() => {
    nomRef.current.focus()
  }, [])

  //Retire l'erreur quand on modifie un champ
  useEffect(() => {
    setErrMsg('')
  }, [civilite, nom, prenom, courriel, telephone, adresse, ville, codePostal])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
       let userEncoded=new URLSearchParams();
        userEncoded.set('civilite',civilite);
        userEncoded.set('nom',nom);
        userEncoded.set('prenom',prenom);
        userEncoded.set('courriel',courriel);
        userEncoded.set('telephone',telephone);
        userEncoded.set('adresse',adresse);
        userEncoded.set('ville',ville);
        userEncoded.set('codePostal',codePostal);

       const response = await axios.post(REGISTER_URL,
           (userEncoded.toString()),
           {
             headers: {'Content-Type': 'application/x-www-form-urlencoded'},
           })

     } catch (err) {
      if (err.response?.status!==200) {
        setErrMsg('Echec lors de l\'inscription');
    }
     }
  }

  return (
    <>
      <HeaderDetail />
      <div className="register">
          <p ref={errRef}>{errMsg}</p>
          <h1 className="register-title">Inscription</h1>
          <form className="register-form" onSubmit={handleSubmit}>
            <div className="register-form-un">
              <label htmlFor="civilite">Civilité:</label>
              <select 
                className="select-input"
                value={civilite}
                id='civilite'
                onChange={(e) => setCivilite(e.target.value)}
                required
                >
                <option value="madame">Madame</option>
                <option value="monsieur">Monsieur</option>
              </select>

              <label htmlFor="nom">Nom:</label>
              <input 
                className="login-input"
                type="text" 
                ref={nomRef}
                id="nom"
                onChange={(e) => setNom(e.target.value)}
                value={nom}
                required
              />  

              <label htmlFor="prenom">Prenom:</label>
              <input 
                className="login-input"
                type="text" 
                id="prenom"
                onChange={(e) => setPrenom(e.target.value)}
                value={prenom}
                required
              />  

              <label htmlFor="courriel">Courriel:</label>
              <input
                className="login-input"
                type="text" 
                id="courriel"
                onChange={(e) => setCourriel(e.target.value)}
                value={courriel}
                required
              />  
            </div>
            <div className="register-form-deux">
              <label htmlFor="telephone">Téléphone:</label>
              <input
                className="login-input"
                type="text" 
                id="telephone"
                onChange={(e) => setTelephone(e.target.value)}
                value={telephone}
                required
              />  

              <label htmlFor="adresse">Adresse:</label>
              <input
                className="login-input"
                type="text" 
                id="adresse"
                onChange={(e) => setAdresse(e.target.value)}
                value={adresse}
                required
              />  

              <label htmlFor="ville">Ville:</label>
              <input
                className="login-input"
                type="text" 
                id="ville"
                onChange={(e) => setVille(e.target.value)}
                value={ville}
                required
              />  

              <label htmlFor="codePostal">Code postal:</label>
              <input
                className="login-input"
                type="text" 
                id="codePostal"
                onChange={(e) => setCodePostal(e.target.value)}
                value={codePostal}
                required
              />  
            </div>
          </form>
          <button className='button-submit'>Inscription</button>
          <div className="login-register">
            <p>Vous possédez déjà un compte ?
              <span>
                <Link className="login-register-link" to="/login">Connexion</Link>
              </span>
            </p>
          </div>
      </div>
    </>
  )
}

export default Register