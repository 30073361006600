import HeaderDetail from './HeaderDetail.jsx'

import { useRef, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { saveToken, saveUser } from '../utils/utils.js'

import '../styles/Login.css'
import technicien from '../assets/technicien.png'

import axios from '../api/axios'
const LOGIN_URL = '/reldefSrv/resources/auth'

const Login = () => {

    const navigate = useNavigate()
    // const location = useLocation()
    // const from = location.state?.from?.pathname || "/"

    const userRef = useRef()
    const errRef = useRef()
  
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')

    //Met le curseur directement sur login
    useEffect(() => {
      userRef.current.focus()
    }, [])

    //Retire l'erreur quand on modifie username ou password
    useEffect(() => {
      setErrMsg('')
    }, [username, password])


    const handleSubmit = async (e) => {
      e.preventDefault()

      try {
        let body = new URLSearchParams();
        body.set('username', username);
        body.set('password', password);

        const response = await axios.post(LOGIN_URL,
          (body.toString()),
          {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          })
        saveToken(response?.data)
        saveUser(username)
        //Remet les champs a vide
        setUsername('');
        setPassword('');
        navigate('/technicien/mes-tickets', { replace: true })
      } catch (err) {
        if (err.response?.status!==200) {
          setErrMsg('Échec lors de la connexion');
      }
      errRef.current.focus();
      }
    }

    return (
    <>
      <HeaderDetail />
      <div className="login-container">
        <div className="login">
          <h1 className="login-title">Connexion</h1>
          <form className="login-form" onSubmit={handleSubmit}>
            {/* <label htmlFor="username">Username:</label> */}
            <input 
              className="login-input login-username"
              type="text" 
              placeholder="Login"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
            />

            {/* <label htmlFor="password">Password:</label> */}
            <input 
              className='login-input login-password'
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />  
            <button className='button-submit'>Connecte-toi</button>
            <p ref={errRef} style={{color: 'red'}}>{errMsg}</p>
          </form>
          <div className="login-register">
            <p>Pas de compte ?
              <span>
                <Link className="login-register-link" to="/register">Inscris-toi</Link>
              </span>
            </p>
          </div>
        </div>
          <div className="login-technicien">
              <img className="login-technicien-icon" src={technicien} alt="img-technicien" />
              <h2 style={{ textAlign: 'center'}}>Technicien</h2>
          </div>
      </div>
    </>
  )
}

export default Login