import Login from "./components/Login"; 
import Register from "./components/Register"; 
import Erreur from "./components/Erreur";
import Layout from "./components/Layout";
import RouterConnexion from "./components/RouterConnexion";
import RequireAuth from "./components/RequireAuth";

import './styles/App.css'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/*Route accessible à tous*/}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="/" element={<Login />} /> 
        {/*Route accessible uniquement si connecté*/}
        <Route element={<RequireAuth />}>
          <Route path="/technicien/*" element={<RouterConnexion />} />
        </Route>
        {/*Route si chemin spécié non connu */}
        <Route path="*" element={<Erreur />} />
      </Route>
    </Routes>
  )
}

export default App;
