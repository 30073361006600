import React from 'react'

export default function InputTextDate({errors, typeField, field, placeHolder, handleChange, values, text}) {
  return (
    <div className='input-container'>
    <input 
      className={`input-create ${errors && errors[field] && 'error'}`}
      type={typeField}
      name={field}
      placeholder={placeHolder}
      autoComplete="off"
      id={field}
      onChange={handleChange}
      value={values[field]}
    />
    <p><label className='label' htmlFor={field}>{text}</label></p>
  </div>
  )
}
