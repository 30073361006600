import React from 'react'
import logo from '../assets/logo.png'
import '../styles/HeaderDetail.css'

const HeaderDetail = () => {
  return (
    <nav className="navBar-login">
        <ul>
            <li><a className="navBar-login-title" href="http://www.cmc-engineering.fr/"><img src={logo} alt="logo"/></a></li>
            <li className="navBar-login-center"><h1>SOSReseau.net - Assistance Informatique </h1><h1><i className="fa-solid fa-phone"></i>08 11 46 75 75</h1></li>
            <li><h1 className="navBar-login-title"><a href="http://www.cmc-engineering.fr/"> www.cmc-engineering.fr</a></h1></li>
        </ul>
    </nav>
  )
}

export default HeaderDetail
