//Utilisé cette instance pour requête sans modifier le header(sans authorization bearer)
import axios from '../api/axios'
//Utilisé cette instance pour requête qui modifie le header(avec authorization bearer)
import axiosInstance from '../services/interceptor'

const PROFIL_URL = '/reldefSrv/resources/techniciens/'
const TICKETS_URL = '/reldefSrv/resources/tickets/'
const FRDT_URL = '/reldefSrv/resources/frdt/'
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
 
const MYTICKETS_URL = '/reldefSrv/resources/techniciens/Tickets/'
const TECHNICIENS_URL = '/reldefSrv/resources/techniciens'
const SITES_URL = '/reldefSrv/resources/site'
const UTILISATEURS_URL = '/reldefSrv/resources/utilisateur/login'
const ATTRIBUTION_URL = '/reldefSrv/resources/frdt/attribution'

const ACTION_URL = '/reldefSrv/resources/actions' 


//Retourne le token de la session 
export const getToken = () => {
    return sessionStorage.getItem(TOKEN_KEY)
}

//Sauvegarde le token dans la session
export const saveToken = userToken => {
    sessionStorage.setItem(TOKEN_KEY, userToken)
}

//Sauvegarde le nom de l'utilisateur
export const saveUser = (user) => {
    sessionStorage.removeItem(USER_KEY);
    sessionStorage.setItem(USER_KEY, user)
}

//Retourne le nom de l'utilisateur connecté
export const getUser = () => {
    return sessionStorage.getItem(USER_KEY)
}

export const getProfilUtilisateur = () => {
    const userName = getUser()
    return axiosInstance.get(PROFIL_URL+userName)
}

export const getAllTickets = (quantite, debut) => {
    return axiosInstance.get(`${TICKETS_URL}${quantite}/${debut}`)
}

export const getAllMyTickets = (quantite, debut, technicien) => {
    return axiosInstance.get(`${MYTICKETS_URL}${quantite}/${debut}/${technicien}`)
} 

export const saveTicket = (utilisateur ,frdt, nomSite) => {
    axiosInstance.post(FRDT_URL+"cree/"+utilisateur+"/"+nomSite, frdt)
}

export const updateTicket = (frdt) => {
    axiosInstance.post(FRDT_URL+"edite", frdt)
}

export const getTechniciens = () => {
    return axiosInstance.get(TECHNICIENS_URL)
}

export const getSites = () => {
    return axiosInstance.get(SITES_URL)
}
export const getUtilisateurs = () => {
    return axiosInstance.get(UTILISATEURS_URL)
}

export const getOcurrEtat = (tickets) => {
    const etats = ['Fermé', 'Réparé', 'En Cours', 'Palliatif', 'Ouvert', 'Sans objet']
    const dataEtat = []
    etats.forEach((etat) => {
      const qtiteEtat = tickets.filter(tk => tk.etat === etat).length
      dataEtat.push({ etat, qtite: qtiteEtat })
  })
  return dataEtat
  }

//Tri ordre decroissant
export const sortTicketDown = (tabTicket) => {
    return tabTicket.sort((a, b) => {
      return new Date(b.dateApparition.substring(0, b.dateApparition.length-5)) - new Date(a.dateApparition.substring(0, a.dateApparition.length-5))
    })
}
  
//Tri ordre croissant
export const sortTicketUp = (tabTicket) => {
    return tabTicket.sort((a, b) => {
      return new Date(a.dateApparition.substring(0, a.dateApparition.length-5)) - new Date(b.dateApparition.substring(0, b.dateApparition.length-5))
    })
}

// recupere les frdt
export const getFrdt = (indice) => {
  return axiosInstance.get(FRDT_URL + indice.toString());
}

export const addAction = (frdt) => {
    axiosInstance.post(ACTION_URL, frdt)
}

export const tabEtat = [{'val': 'Ouvert', 'labelVal': 'Ouvert'},
{'val': 'En Cours', 'labelVal': 'En cours'},
{'val': 'Sans Object', 'labelVal': 'Sans objet'},
{'val': 'Réparé', 'labelVal': 'Réparé'},
{'val': 'Palliatif', 'labelVal': 'Palliatif'}]

export const tabDefault = [{'val': 'permanent', 'labelVal': 'Permanent'},
{'val': 'intermittent', 'labelVal': 'Intermittent'},
{'val': 'passager', 'labelVal': 'Passager'}]

export const tabProduit = [{'val': 'telephone', 'labelVal': 'Téléphone'},
{'val': 'internet', 'labelVal': 'Internet/Réseau'},
{'val': 'pc', 'labelVal': 'Défaut PC'},
{'val': 'fax', 'labelVal': 'Fax/Imprimante'},
{'val': 'autres', 'labelVal': 'Autres'}]

export const tabUrgence = [{'val': 'bloquant', 'labelVal': 'Bloquant'},
{'val': 'perturbant', 'labelVal': 'Perturbant'},
{'val': 'genant', 'labelVal': 'Gênant'},
{'val': 'ameliorer', 'labelVal': 'A améliorer'}]