import React from 'react'

/* isMyticket permet d'ajouter un champs d'attribution de ticket uniquement si nous sommes
dans la page de nos tickets */
const HeaderTickets = ({ isMyTicket }) => {
  return (
    <thead>
      <tr>
          <th>Billet de référence</th>
          <th>État du ticket</th>
          <th>Site</th>
          <th>Matériel</th>
          <th>Signalisation de la date</th>
          <th>Date de résolution</th>
          <th>Observation</th>
          {isMyTicket && <th>Attribuer un ticket</th>}
      </tr>
    </thead>
  )
}

export default HeaderTickets