import { useState } from "react";

const useForm = () => {
    const [values, setValues] = useState({
        site: '',
        etat: '',
        login: '',
        dateApparition: '',
        refMateriel: ''
    })
    const [isFilter, setIsFilter] = useState(false)

    /* A chaque changement de valeur sur un champs on modifie le state en conséquence en
    récupérant son name et sa value */
    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleFilter = (e) => {
        setIsFilter(e.target.checked)
    }

    const handleDelete =  (e) =>{
        e.preventDefault()
        setValues({
            site: '',
            etat: '',
            login: '',
            dateApparition: '',
            refMateriel: ''
        })
    }

    return { handleChange, values, handleFilter, isFilter, handleDelete }
}

export default useForm;

