import axios from "../api/axios";
import { getToken } from '../utils/utils.js'

// pas d'ennvoi de token pour exclude_url
const EXCLUDED_URL = "register";

//Copie de l'objet axios de base
const axiosInstance = axios

//Intercepte la requete et ajoute le token dans Authorization du header sauf si c'est la requête d'inscription
axiosInstance.interceptors.request.use((request) => {
        const token = getToken()
        if(token && !request.url.includes(EXCLUDED_URL)){
            request.headers["Authorization"] = `Bearer ${token}`
        }
        return request
    })

export default axiosInstance
