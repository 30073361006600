import React from 'react'
import Header from './Header'
import useFormCreate from '../utils/useFormCreate';
import validate from '../utils/validateInfo';

import { useContext, useState, useEffect } from 'react';
import Snackbar from './SnackBar/SnackBar';
import SnackbarContext from './store/snackbar-context';
import InputTextDate from './InputTextDate';
import InputTextArea from './InputTextArea';
import InputRadio from './InputRadio';

import { getSites, getUtilisateurs, tabDefault, tabProduit, tabUrgence } from '../utils/utils';

import '../styles/TicketCreation.css'

const TicketCreation = () => {

  const { handleChange, handleSubmit, values, setValues, errors } = useFormCreate(validate)
  const [sitesVariable, setSitesVariable] = useState([])
  const [sites, setSites] = useState([])
  const [usersVariable, setUsersVariable] = useState([])
  const [users, setUsers] = useState([])
  const snackbarCtx = useContext(SnackbarContext)
  const [searchSiteActive, setSearchSiteActive] = useState(false)
  const [searchUserActive, setSearchUserActive] = useState(false)


  //Affectation des sites vers sites et sitesVariables
  //La différence entre les 2 est que sites ne change jamais il contient toujours la liste
  //de tous les sites alors que sitesVariables est utiliser pour le filtre donc destiné à changer
  useEffect(() => {
    async function fetchSites(){
      try {
        const responseSites = await getSites()
        const { data } = await responseSites
        setSitesVariable(data)
        setSites(data)
      } catch (error) {
      }
    }
    fetchSites()
  }, [])

  //SAME
  useEffect(() => {
    async function fetchUsers(){
      try {
        const responseUsers = await getUtilisateurs()
        const { data } = await responseUsers
        setUsersVariable(data)
        setUsers(data)
      } catch (error) {
      }
    }
    fetchUsers()
  }, [])

  //Afficher les sites avec le filtre des que le champ site n'est plus vide
  const onSiteChange = async (e) => {
    if(e.target.value.length > 0){
      setSearchSiteActive(true)
    }else{
      setSearchSiteActive(false)
    }
    handleChange(e)

    let filter = e.target.value.toLowerCase()
    let resSites = sites.filter((e) => {
      let dataFilter = e?.nom?.toLowerCase()
      return dataFilter.indexOf(filter) !== -1
    })
    // console.log(resSites);

    setSitesVariable(resSites)
  } 

  //Lors d'un clique sur un site remplir l'input  avec  le nom  du site et cacher la liste des sites 
  const changeSite = (site) => {
    setValues({...values, site})
    setSearchSiteActive(false)
  }

  //Afficher les utilisateurs avec le filtre des que le champ utilisateur n'est plus vide
  const onUserChange = async (e) => {
    if(e.target.value.length > 0){
      setSearchUserActive(true)
    }else{
      setSearchUserActive(false)
    }
    handleChange(e)

    let filter = e.target.value.toLowerCase()
    let resUsers = users.filter((nom) => {
      let dataFilter = nom.toLowerCase()
      return dataFilter.indexOf(filter) !== -1
    })

    setUsersVariable(resUsers)
  } 

  //Lors d'un clique sur un site remplir l'input  avec  le nom  du site et cacher la liste des sites 
  const changeUser = (user) => {
    setValues({...values, user})
    setSearchUserActive(false)
  }

  return (
    <div>
      <Header />
      {snackbarCtx.isDisplayed && <Snackbar />}
        <div className="tickets-container box-create">
          <h2>Information sur les conditions de l'incident</h2>
          <ul className="tickets-infos">
            <li>Date,</li>
            <li>lieu,</li>
            <li>materiel impacté</li>
          </ul>

          <form className="creation-form">
            <div className="left">
              <div className="column">

                <div className='input-container filterBlock'>
                    <input 
                      className={`input-create ${errors && errors.site && 'error'}`}
                      type='text'
                      name='site'
                      placeholder='Site *'
                      autoComplete="off"
                      id='site'
                      onChange={onSiteChange}
                      value={values.site}
                    />
                    <p><label className='label' htmlFor='site'>Site en question</label></p>
                    <ul className={searchSiteActive ? 'activeSite' : ''}>
                    {sitesVariable.map((res) => {
                      return <li key={res.idsite} onClick={()=>changeSite(res.nom)} >{res.nom}</li>
                    })}
                    </ul>
                  </div>

                  <div className='input-container filterBlock'>
                    <input 
                      className={`input-create ${errors && errors.user && 'error'}`}
                      type='text'
                      name='user'
                      placeholder='Utilisateur *'
                      autoComplete="off"
                      id='user'
                      onChange={onUserChange}
                      value={values.user}
                    />
                    <p><label className='label' htmlFor='user'>Utilisateur concerné</label></p>
                    <ul className={searchUserActive ? 'activeUser' : ''}>
                    {usersVariable.map((res, index) => {
                      return <li key={`${res}-${index}`} onClick={()=>changeUser(res)} >{res}</li>
                    })}
                    </ul>
                  </div>

                  <InputTextDate  
                    errors={errors}
                    typeField='text'
                    field='materiel'
                    placeHolder= 'Matériel *'
                    handleChange= {handleChange}
                    values={values}
                    text='Matériel en question'
                  />

                  <InputTextDate  
                    errors={errors}
                    typeField='date'
                    field='apparition'
                    handleChange= {handleChange}
                    values={values}
                    text="Date d'apparition du problème"
                  />
                </div>

              <div className="column">

                <InputRadio 
                  labelName="Type de défaut :"
                  errors={errors}
                  field="type"
                  values={values}
                  tabElem={tabDefault}
                  handleChange={handleChange}
                />

                <InputRadio 
                  labelName="Produit concerné :"
                  errors={errors}
                  field="produit"
                  values={values}
                  tabElem={tabProduit}
                  handleChange={handleChange}
                />

                <InputRadio 
                  labelName="Urgence :"
                  errors={errors}
                  field="urgence"
                  values={values}
                  tabElem={tabUrgence}
                  handleChange={handleChange}
                />
                </div>
            </div>

            <div className="right">
              <div className="column">
                  <InputTextArea 
                    field='resume'
                    placeHolder= 'Résumé'
                    handleChange={handleChange}
                    values={values}
                    text="Conditions d’apparition, le probleme est-il recurrent?"
                  />

                  <InputTextArea 
                    field='description'
                    placeHolder= 'Description du problème'
                    handleChange={handleChange}
                    values={values}
                    text="Le probleme est-il reproductible, et comment ?"
                  />

              </div>
            </div>
          </form>
            <button  onClick={handleSubmit} className='button-submit bottom-button'>Valider</button>
        </div>
        
      </div>
  )
}
export default TicketCreation