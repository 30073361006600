import React from 'react'

export default function InputTextArea({field, placeHolder, handleChange, values, text }) {
  return (
    <div className="input-container">
    <p><label htmlFor={field}>{text}</label></p>
    <textarea 
      placeholder={placeHolder}
      name={field}
      id={field}
      onChange={handleChange}
      value={values[field]}
    />
    </div>
    )
}
