import React from 'react';
import '../styles/TicketDetail.css'
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header'
import useFormUpdate from '../utils/useFormUpdate';
import validate from '../utils/validateInfo';

import Snackbar from './SnackBar/SnackBar';
import SnackbarContext from './store/snackbar-context';
import InputTextDate from './InputTextDate';
import InputTextArea from './InputTextArea';
import InputRadio from './InputRadio';

import { getUser, getFrdt, getSites, addAction, tabEtat, tabDefault, tabProduit, tabUrgence } from '../utils/utils';


const TicketDetail = () => {
    const snackbarCtx = useContext(SnackbarContext)
    const params = useParams()

    const [sitesVariable, setSitesVariable] = useState([])
    const [sites, setSites] = useState([])
    const [searchSiteActive, setSearchSiteActive] = useState(false)
    const [msgTechnicien, setMsgTechnicien] = useState('')
    
    const [detailsTicket, setDetailsTicket] = useState()
    const { handleChange, handleSubmit, values, setValues, errors } = useFormUpdate(validate, detailsTicket)
    var moment = require('moment')

    useEffect(() => {
        async function getDetails(){
            try {
                const response = await getFrdt(params.id)
                const {data} = await response
                console.log(data);
                setDetailsTicket(data)
            } catch (error) {
            }
        }
        getDetails()
    }, [])
    useEffect(() => {
        setValues({
            site: detailsTicket?.siteIdsite?.nom,
            materiel: detailsTicket?.refMateriel,
            etat: detailsTicket?.etat,
            creation: detailsTicket?.date ? moment.utc(detailsTicket?.date.substring(0, detailsTicket?.date.length-5)).format('YYYY-MM-DD') : '', 
            apparition: detailsTicket?.dateApparition ? moment.utc(detailsTicket?.dateApparition.substring(0, detailsTicket?.dateApparition.length-5)).format('YYYY-MM-DD') : '', 
            previsionnelle: detailsTicket?.dateIntervention ? moment.utc(detailsTicket?.dateIntervention.substring(0, detailsTicket?.dateIntervention.length-5)).format('YYYY-MM-DD') : '', 
            resolution: detailsTicket?.dateResolution ? moment.utc(detailsTicket?.dateResolution.substring(0, detailsTicket?.dateResolution.length-5)).format('YYYY-MM-DD') : '', 
            type: detailsTicket?.permanent ? 'permanent' : detailsTicket?.intermittent ? 'intermittent' : detailsTicket?.passager ? 'passager' : '',
            produit: detailsTicket?.telephone ? 'telephone' : detailsTicket?.internetReseau ? 'internet' : detailsTicket?.pc ? 'pc' : detailsTicket?.faxImp ? 'fax' : detailsTicket?.autre ? 'autres' : '',
            urgence: detailsTicket?.bloquant ? 'bloquant' : detailsTicket?.perturbant ? 'perturbant' : detailsTicket?.genant ? 'genant' : detailsTicket?.aameliorer ? 'ameliorer' : '',
            resume: detailsTicket?.resume,
            description: ''
        })    
        // console.log(values);

    }
    , [detailsTicket])

     //Affectation des sites vers sites et sitesVariables
  //La différence entre les 2 est que sites ne change jamais il contient toujours la liste
  //de tous les sites alors que sitesVariables est utiliser pour le filtre donc destiné à changer
  useEffect(() => {
    async function fetchSites(){
      try {
        const response = await getSites()
        const { data } = await response
        setSitesVariable(data)
        setSites(data)
      } catch (error) {
      }
    }
    fetchSites()
  }, [])

  //Afficher les sites avec le filtre des que le champ site n'est plus vide
  const onSiteChange = async (e) => {
    if(e.target.value.length > 0){
      setSearchSiteActive(true)
    }else{
      setSearchSiteActive(false)
    }
    handleChange(e)

    let filter = e.target.value.toLowerCase()
    let resSites = sites.filter((e) => {
      let dataFilter = e?.nom?.toLowerCase()
      return dataFilter.indexOf(filter) !== -1
    })
    // console.log(resSites);

    setSitesVariable(resSites)
  } 

  //Lors d'un clique sur un site remplir l'input  avec  le nom  du site et cacher la liste des sites 
  const changeSite = (site) => {
    setValues({...values, site})
    setSearchSiteActive(false)
  }

  const sendMessage = () => {
    //Si le msg est vide on n'accepte pas l'envoi de msg
    if(msgTechnicien === ""){
      return
    }
    var date = new Date().toISOString()
    // date = date.substring(0, date.length-5)+'Z[UTC]'
    //Création d'une nouvelle action avec le rapport du technicien
    const newAction = {"auteur":getUser(), "client":false,"date":date,"idactions": null,"rapport":msgTechnicien,"technicien":true, "frdtIdfrdt": detailsTicket}
    
    addAction(newAction)
    
    const msg = "Message envoyé, veuillez recharger la page pour le voir...";
    snackbarCtx.displayMsg(msg);
    setMsgTechnicien('')
  }

    return (
        <div>
          <Header />
          {snackbarCtx.isDisplayed && <Snackbar />}
            <div className="tickets-container box-create">
            <h2>Prise en compte et evolution du traitement</h2>
              <ul className="tickets-infos">
                <li>Date,</li>
                <li>lieu,</li>
                <li>materiel impacté</li>
              </ul>
    
              <form className="creation-form">
                <div className="left">
                  <div className="column">
                      <InputTextDate  
                        errors={errors}
                        typeField='text'
                        field='site'
                        placeHolder= 'Site'
                        handleChange= {onSiteChange}
                        values={values}
                        text='Site en question'
                        isFilter='yes'
                        searchSiteActive={searchSiteActive}
                        sitesVariable={sitesVariable}
                        changeSite={changeSite}
                      />
    
                      <InputTextDate  
                        errors={errors}
                        typeField='text'
                        field='materiel'
                        placeHolder= 'Matériel'
                        handleChange= {handleChange}
                        values={values}
                        text='Matériel en question'
                      />

                    <InputRadio 
                      labelName="Etat du ticket :"
                      errors={errors}
                      field="etat"
                      values={values}
                      tabElem={tabEtat}
                      handleChange={handleChange}
                    />
    
                    </div>
    
                  <div className="column">
    
                    <InputRadio 
                      labelName="Type de défaut :"
                      errors={errors}
                      field="type"
                      values={values}
                      tabElem={tabDefault}
                      handleChange={handleChange}
                    />
    
                    <InputRadio 
                      labelName="Produit concerné :"
                      errors={errors}
                      field="produit"
                      values={values}
                      tabElem={tabProduit}
                      handleChange={handleChange}
                    />
    
                    <InputRadio 
                      labelName="Urgence :"
                      errors={errors}
                      field="urgence"
                      values={values}
                      tabElem={tabUrgence}
                      handleChange={handleChange}
                    />
                    </div>
                </div>
    
                <div className="right">
                  <div className="column">
                      <InputTextArea 
                        field='resume'
                        placeHolder= 'Résumé'
                        handleChange={handleChange}
                        values={values}
                        text="Conditions d’apparition, le probleme est-il recurrent?"
                      />
    
                      <InputTextArea 
                        field='description'
                        placeHolder= 'Description du problème'
                        handleChange={handleChange}
                        values={values}
                        text="Le probleme est-il reproductible, et comment ?"
                      />
                  </div>
                  <div className="column">
                      <InputTextDate  
                        errors={errors}
                        typeField='date'
                        field='creation'
                        handleChange= {handleChange}
                        values={values}
                        text="Date de création du ticket"
                      />
                      <InputTextDate  
                        errors={errors}
                        typeField='date'
                        field='apparition'
                        handleChange= {handleChange}
                        values={values}
                        text="Date d'apparition du ticket"
                      />
                      <InputTextDate  
                        errors={errors}
                        typeField='date'
                        field='previsionnelle'
                        handleChange= {handleChange}
                        values={values}
                        text="Date prévisionnelle de résolution"
                      />
                      <InputTextDate  
                        errors={errors}
                        typeField='date'
                        field='resolution'
                        handleChange= {handleChange}
                        values={values}
                        text="Date de résolution du ticket"
                      />
                  </div>
                </div>
              </form>
                <button onClick={handleSubmit} className='button-submit bottom-button'>Valider</button>
            </div>

            <div className="add-msg">
              <h2>Entrer un message à destination du client</h2>
              <textarea 
                className="add-msg-input"
                type="text" 
                placeholder="Entrer votre message"
                onChange={(e) => setMsgTechnicien(e.target.value)}
                value={msgTechnicien}
              />
              <button onClick={sendMessage}>Envoyer <i className="fa-solid fa-paper-plane"></i></button>
            </div>

            <div className="echange-action">
              {detailsTicket?.actionsCollection.length === 0 ?
              (<h3>Aucun message n'a été posté !</h3>)
              :
              detailsTicket?.actionsCollection.map((val) => (
                  val.technicien && val.rapport !== '' ? (
                    <div key={val.idactions} className="echange-action-technicien">
                      <div className="msg-technicien">
                          <h3>Technicien</h3>
                          <p>{val.rapport}</p>
                      </div>
                    </div>
                  )
                  : 
                  val.client && val.rapport !== '' ? (
                    <div key={val.idactions} className="echange-action-client">
                      <div className="msg-client">
                        <h3>Client</h3>
                        <p>{val.rapport}</p>
                      </div>
                    </div>
                  )
                  :
                  (<h3>Aucun message n'a été posté !</h3>)
            ))}
            </div>
            
          </div>
      )
}

export default TicketDetail;
