import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../hooks/useAuth"

const TOKEN_KEY = 'auth-token';

export const RequireAuth = () => {
    const location = useLocation()
  

  return (
    //Si utilisateur connecté alors on retourne les composants enfants de requireAuth
    //Si déconnecté on redirige vers login et on lui donne l'attribut from pour qu'il puisse
    //se rediriger a la connection sur la page voulu initialement au lieu d'aller vers l'accueil 
    Boolean(sessionStorage.getItem(TOKEN_KEY))
        ? <Outlet />
        : <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireAuth