import React from 'react'
import { useState, useEffect } from 'react'
import axios from '../api/axios'
import { getAllTickets, getOcurrEtat, sortTicketDown, sortTicketUp } from '../utils/utils'
import { Loader } from '../styles/Atoms'
import ReactPaginate from "react-paginate"
import Header from './Header'
import Ticket from './Ticket'
import HeaderTickets from './HeaderTickets'
import InputTextDate from './InputTextDate';
import '../styles/TicketList.css'

import useFormList from '../utils/useFormList';

const TICKETCOUNT_URL = "/reldefSrv/resources/tickets/count"

const TicketList = () => {
  const [allTickets, setAllTickets] = useState([])
  const [ticketList, setTicketList] = useState([])
  const [ticketListPer10, setTicketListPer10] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [nbTickets, setNbTickets] = useState(0)
  const [nbPage, setNbPage] = useState(0)
  let ticketPerPage = 10
  var moment = require('moment')


  const { handleChange, values, handleFilter, isFilter, handleDelete } = useFormList()

  //Au chargement de la page on récupère le nb de pages et de tickets
  useEffect(() => {
    async function ticketsCount() {
      try {
        const response = await axios.get(TICKETCOUNT_URL)
        const { data } = await response
        const nbPage = Math.ceil(data / 10)
        setNbTickets(data)
        setNbPage(nbPage)
      } catch (error) {
      }
    }
    ticketsCount()
  }, [])

    /*
    Des qu'on a récupéré le nb de page on injecte tout les tickets dans ticketList
    ainsi que dans AllTickets et on insere les 10 premiers resultats dans 
    ticketListPer10
    */
   useEffect(() => {
    async function fetchTickets(){ 
      setIsLoading(true)
      try {
          const response = await getAllTickets(nbTickets, 1)
          const { data } = await response
          console.log(data)
          setTicketList(data)
          setAllTickets(data)
          setTicketListPer10(data.slice(0, 10))
      } catch (error) {
        
      }finally{
        setIsLoading(false)
      }
    }
    fetchTickets()
  }, [nbTickets])

  //Renvoi les données de la page $currentPage
  const fetchNewTickets = (currentPage) => {
    const startIndex = currentPage * ticketPerPage - ticketPerPage;
    const endIndex = startIndex + ticketPerPage;
    return ticketList.slice(startIndex, endIndex);
  }

  //Modifie les tickets affichés en fonction du numéro de page sélectionné
  const changePage = async (data) => {
    let currentPage = data.selected + 1
    const newTickets = fetchNewTickets(currentPage)

    setTicketListPer10(newTickets)
  }
 
  //Met a jour le nb de page et les tickets 
  function majTicket(tabTicket){
    setTicketList(tabTicket)
    setTicketListPer10(tabTicket.slice(0,10))
    setNbPage(Math.ceil(tabTicket.length / 10))
  }

  const handleSubmit =  (e) =>{
    e.preventDefault()
    // Copie de tout les tickets dans la variable res qui s'occupera de contenir les tickets filtrés
    let res = allTickets

    //Liste des noms de filtres
    const tabInput = ['site', 'etat', 'login', 'dateApparition', 'refMateriel']
    //etourne un tableau contenant uniquement les champs filtres non vide
    const tabInputFills = tabInput.filter((input) => values[input].length>0)
    //Filtre en fonction des champs remplis
    tabInputFills.forEach((input) => {
      res = res.filter((ticket) => {
        //Pour convertir la date au bon format
        if(input === 'dateApparition'){
          //Utilisation d'une bibliotheque légère
          var moment = require('moment')
          const date = ticket[input]
          // console.log('1 => '  + values[input] + ' | 2 => ' + moment.utc(date.substring(0, date.length-5)).format('YYYY-MM-DD'))
          return values[input] === moment.utc(date.substring(0, date.length-5)).format('YYYY-MM-DD')
        }else{
          return values[input].toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "") === ticket[input].toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
        }
      })
    })
    //Si tableau de filtre contient des tickets alors on assigne a ticket list le tab filtré
    if(tabInputFills.length > 0){
      majTicket(res)
    }else{
      /*Grace a la sauvegarde de tout les tickets dans allTickets on reaffiche
      tout les tickets des qu'une recherche a lieu qu'avec des champs vide*/
      majTicket(allTickets)
    }
}

  return (
    <div>
      <Header />
       {isLoading ?    
       <Loader/> :      
       <div className="page-container">
          <div className={`box-filter ${isFilter && 'open'}`}>
            <h3>Critères de recherche</h3>
            <form className='filter-form'>

              {/* Ceci est un composant que j'ai crée car il se répetait (voir InputTextDate.jsx) */}
              <InputTextDate  
                    typeField='text'
                    field='site'
                    placeHolder="Site"
                    handleChange= {handleChange}
                    values={values}
                    text="Filtrer par site"
              />

              <InputTextDate  
                    typeField='text'
                    field='etat'
                    placeHolder="État"
                    handleChange= {handleChange}
                    values={values}
                    text="Filtrer par état du ticket"
              />

              <InputTextDate  
                    typeField='text'
                    field='login'
                    placeHolder="Client"
                    handleChange= {handleChange}
                    values={values}
                    text="Filtrer par client ayant ouvert un ticket"
              />

              <InputTextDate  
                    typeField='date'
                    field='dateApparition'
                    handleChange= {handleChange}
                    values={values}
                    text="Filtrer par état du ticket"
              />

              <InputTextDate  
                    typeField='text'
                    field='refMateriel'
                    placeHolder="Matériel"
                    handleChange= {handleChange}
                    values={values}
                    text="Filtrer par matériel"
              />

              <div className="filter-container-button">
                <button  onClick={handleSubmit} className='button-submit button-filter'>Appliquer</button>
                <button onClick={handleDelete} className='button-submit button-filter'>Effacer</button>
              </div>

            </form>
          </div>
          <div className="tickets-container">    
              <h2>Liste des incidents signalés</h2>
              <ul className="tickets-infos">
              {getOcurrEtat(ticketList).map(({etat, qtite}, index) => (
                <li key={`${index}-${etat}`}>{etat} : {qtite}</li>
              ))}
              </ul>
              <div className="toggle-filtre">
                  <input onClick={handleFilter} type="checkbox" className="toggle-filtre-button" id='toggle-filtre-button'/>
                  <label htmlFor="toggle-filtre-button"></label>
                  <p>Appliquer un filtre</p>
              </div>
              <div className="btn-sort-container">
                <div
                  id="down"
                  onClick={() => majTicket(sortTicketDown(ticketList))}
                >
                  Décroissant<span>➜</span>
                </div>
                <div
                  id="up"
                  onClick={() => majTicket(sortTicketUp(ticketList))}
                >
                  Croissant<span>➜</span>
                </div>
              </div>
              <table className='tickets'>
                <HeaderTickets />
                {ticketListPer10.map((ticket) => (                    
                    <Ticket
                      key={`${ticket.idfrdt}-${ticket.reffrdt}`}
                      dateApparition={moment.utc(ticket.dateApparition.substring(0, ticket.dateApparition.length-5)).format('DD-MM-YYYY')}
                      dateResolution={ ticket.dateResolution ? moment.utc(ticket.dateResolution.substring(0, ticket.dateResolution.length-5)).format('DD-MM-YYYY') : ''}
                      etat={ticket.etat}
                      refMateriel={ticket.refMateriel}
                      reffrdt={ticket.reffrdt}
                      resume={ticket.resume}
                      site={ticket.site}
                    />
                  ))}
              </table>
              <ReactPaginate 
                    previousLabel={"< Précédent"}
                    nextLabel={"Suivant >"}
                    breakLabel={"..."}
                    onPageChange={changePage}
                    pageCount={nbPage}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={4}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    breakLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    activeClassName={"active"}
              />
          </div>
       </div>    
       }
    </div>
  )
}

export default TicketList