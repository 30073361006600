import Header from "./Header"
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { getProfilUtilisateur } from "../utils/utils"
import { Loader } from '../styles/Atoms'
import '../styles/UserInfo.css'
import technicien from '../assets/technicien.png'

const UserInfo = () => {
  const [infoUser, setInfoUser] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  
  useEffect(() => {
        async function fetchUser(){
          setIsLoading(true)
          try {
            const response = await getProfilUtilisateur()
            console.log(response)
            setInfoUser(response?.data)
          } catch (error) {
            
          } finally{
            setIsLoading(false)
          }
        }
        fetchUser()
      }, [])
      
      const deconnexion = async () => {
        window.sessionStorage.clear();
        navigate('/login')
      }

      return (
    <div>
        <Header />
        <div className="informations">
            {isLoading ?
            <Loader /> : 
            (
              <>
             <div className="informations-container">
                <h1 className="informations-title">Mes informations</h1>

                <div className="informations-label">Nom : <span className="informations-data">{ infoUser?.nom ? infoUser?.nom : '...' }</span></div>   
                <div className="informations-label">Prénom : <span className="informations-data">{ infoUser?.prenom ? infoUser?.prenom : '...' }</span></div>   
                <div className="informations-label">Login : <span className="informations-data">{ infoUser?.authIdauth?.login ? infoUser?.authIdauth?.login : '...' }</span></div>
                <div className="informations-label">Email : <span className="informations-data">{ infoUser?.mail ? infoUser?.mail : '...'}</span></div>
                <div className="informations-label">Tel. : <span className="informations-data">{ infoUser?.telephone ? infoUser?.telephone : '...' }</span></div>

                <div className="logout"> 
                  <button className="logout-button" onClick={deconnexion}>Déconnexion</button>
                </div>
             </div>
              <div className="informations-technicien">
                  <img className="informations-technicien-icon" src={technicien} alt="img-technicien" />
                  <h2 style={{ textAlign: 'center'}}>Technicien</h2>
              </div>
              </>
            )     
            }
          </div>

    </div>
  )
}

export default UserInfo