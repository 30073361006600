import { useNavigate } from "react-router-dom"
import '../styles/Erreur.css'

const Erreur = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  
  return (
    <div className="page-erreur">
        <h1>Page d'erreur</h1>
        <p>Le chemin spécifié n'est pas connu !</p>
        <button onClick={goBack}>Retour</button>
    </div>
  )
}

export default Erreur