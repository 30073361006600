export default function validateInfo(values) {
    let errors = {};
    
    //Si un des champs est vide alors on l'ajoute dans l'objet contenant out les champs ayant une erreur
    for(var value in values)
    {
      if (!values[value] && !values[value].trim() && value !== 'previsionnelle' && value !== 'resolution' && value !== 'resume' && value !== 'description') {
        errors[value] = true;
      }
    }

    // values.map((champ) => {
    //   if (!values.champ && !values.champ.trim()) {
    //     errors.champ = true;
    //   }
    // })
  
    // if (!values.site && !values.site.trim()) {
    //   errors.site = true;
    // }
    // if (!values?.user && !values?.user?.trim()) {
    //   errors.user = true;
    // }
    // if (!values.materiel && !values.materiel.trim()) {
    //   errors.materiel = true;
    // }
    // if (!values.apparition && !values.apparition.trim()) {
    //   errors.apparition = true;
    // }
    // if (!values.type && !values.type.trim()) {
    //   errors.type = true;
    // }
    // if (!values.produit && !values.produit.trim()) {
    //   errors.produit = true;
    // }
    // if (!values.urgence && !values.urgence.trim()) {
    //   errors.urgence = true;
    // }

    return {errors, 'isError': Object.keys(errors).length};
  }