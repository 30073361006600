import React from 'react'
import axiosInstance from '../services/interceptor'

const ATTRIBUTION_URL = '/reldefSrv/resources/frdt/attribution'

const Ticket = ({ click, ticket, isMyTicket, dateApparition, dateResolution, etat, idfrdt, login, refMateriel, reffrdt, resume, site, techniciens, ticketsCount }) => {

  //Permet d'attribuer un ticket à un autre technicien
  const attributeTo = async (e) => {
    if(e.target.value === "---"){
      return
    }else{
      try{
        const response = await axiosInstance.post(ATTRIBUTION_URL,
          ({'idFrdt': idfrdt,'idTechnicien': e.target.value}),
          {
            headers: {'Content-Type': 'application/json',
                      'Accept': 'application/json'},
          })
          //Maj du nb de ticket impérative pour que la page se recharge et que le ticket disparaisse
          ticketsCount()
      }catch(err){
        console.log(err)
      }
    }
  }

  return (
    //Au clique rediriger vers la page de détail d'un ticket
    <tbody onClick={click}>
        <tr className={isMyTicket && etat === 'Fermé' ? 'my-ticket ferme' : isMyTicket && etat === 'Ouvert' ? 'my-ticket ouvert' : isMyTicket && etat === 'Palliatif' ? 'my-ticket paliatif' : isMyTicket && etat === 'Réparé' ? 'my-ticket repare' : isMyTicket && etat === 'En Cours' ? 'my-ticket en-cours' : ''}>
          <td>{reffrdt}</td>
          <td>{etat}</td>
          <td>{site}</td>
          <td>{refMateriel}</td>
          <td>{dateApparition}</td>
          <td>{dateResolution}</td>
          <td>{resume}</td>
          {isMyTicket &&
          <td className='test'>
            <select
              className='test'
              onChange={attributeTo}
            >
                <option value="---">---</option>
                {techniciens.map((technicien) => (
                  <option key={`${technicien.nom}-${technicien.idTechnicien}`} value={technicien.authIdauth.idAuth}>{technicien.authIdauth.login}</option>
                ))}
            </select>
          </td>
          }
        </tr>
  </tbody>
  )
}

export default Ticket