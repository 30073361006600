import { useState, useContext } from "react";
import { updateTicket } from './utils'

import SnackbarContext from "../components/store/snackbar-context";

const useFormUpdate = (validate, detailsTicket) => {
    const [values, setValues] = useState({
        site: '',
        materiel: '',
        etat: '',
        creation: '',
        apparition: '', 
        previsionnelle: '',
        resolution: '',
        type: '',
        produit: '',
        urgence: '',
        resume: '',
        description: ''
    })

    // On récupère le contexte pour pouvoir modifier les variables qu'il nous envoi
    const snackbarCtx = useContext(SnackbarContext)

    const [errors, setErrors] = useState({})

    /* A chaque changement de valeur sur un champs on modifie le state en conséquence en
    récupérant son name et sa value */
    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit =  (e) =>{
        e.preventDefault()
        //errors est un objet contenant tout les champs non rempli
        //isError renvoi la taille de cet objet pour vérifier s'il y a des erreurs
        const {errors, isError} = validate(values)
        setErrors(errors)
        console.log(errors)
        //Affichage de la SNACKBAR avec un message négatif
        if(isError>0){
            const msg = "Un des champs requis n\'est pas remplis";
            snackbarCtx.displayMsg(msg);
        }
        // Création d'un ticket et affichage de la SNACKBAR avec un message positif
        else{
            var dateCreation = values.creation !== '' ? new Date(values.creation).toISOString() : ''
            var dateApparition = values.apparition !== '' ? new Date(values.apparition).toISOString() : ''
            var datePrevisionnelle = values.previsionnelle !== '' ? new Date(values.previsionnelle).toISOString() : ''
            var dateResolution = values.resolution !== '' ? new Date(values.resolution).toISOString() : ''
            let fdef2 = {
                reffrdt: detailsTicket.reffrdt,
                idfrdt: detailsTicket.idfrdt,
                siteIdsite: detailsTicket.siteIdsite,
                technicienIdtechnicien: detailsTicket.technicienIdtechnicien,   
                utilisateurIdUtilisateur: detailsTicket.utilisateurIdUtilisateur,
                utilisateurIdutilisateur: detailsTicket.utilisateurIdutilisateur,
          
                refMateriel: values.materiel,
          
                permanent: values.type === "permanent",
                intermittent: values.type === "intermittent",
                passager: values.type === "passager",
          
                telephone: values.produit === "tel",
                internetReseau: values.produit === "inet",
                pc: values.produit === "defPC",
                faxImp: values.produit === "fax",
                autre: values.produit === "autres",
          
                bloquant: values.urgence === "bloquant",
                perturbant: values.urgence === "perturbant",
                genant: values.urgence === "genant",
                aameliorer: values.urgence === "ameliorer",
          
                resume: values.resume,
                description: values.description,
          
                etat: values.etat,
                date: values.creation !== '' ? dateCreation.substring(0, dateCreation.length-5)+'Z[UTC]' : '',
                dateApparition: values.apparition !== '' ? dateApparition.substring(0, dateApparition.length-5)+'Z[UTC]' : '',
                dateIntervention: values.previsionnelle !== '' ? datePrevisionnelle.substring(0, datePrevisionnelle.length-5)+'Z[UTC]' : '',
                dateResolution: values.resolution !== '' ? dateResolution.substring(0, dateResolution.length-5)+'Z[UTC]' : '',

                actionsCollection: []
                // actionsCollection: detailsTicket.actionsCollection
              }
              console.log(fdef2);

            updateTicket(fdef2)
            const msg = "Votre ticket a bien été mis à jour";
            snackbarCtx.displayMsg(msg);
        }
    }

    return { handleChange, handleSubmit, values, setValues, errors }
}

export default useFormUpdate;

