import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import Header from './Header'
import Ticket from './Ticket'
import HeaderTickets from './HeaderTickets'
import { getAllMyTickets, getTechniciens, getUser, getOcurrEtat, sortTicketDown, sortTicketUp } from '../utils/utils'
import { Loader } from '../styles/Atoms'
import ReactPaginate from "react-paginate"
import '../styles/TicketList.css'

const MYTICKETCOUNT_URL = "/reldefSrv/resources/techniciens/Tickets/count/"

const MyTickets = () => {
  const [myTicketList, setMyTicketList] = useState([])
  const [myTicketListPer10, setMyTicketListPer10] = useState([])
  const [myIsLoading, setMyIsLoading] = useState(false)
  const [nbMyTickets, setNbMyTickets] = useState(0)
  const [nbMyPage, setNbMyPage] = useState(0)
  const [techniciens, setTechniciens] = useState([])
  let ticketPerPage = 10
  var moment = require('moment')
  
  const navigate = useNavigate()

    //Au chargement de la page on récupère le nb de pages et de tickets
    async function ticketsCount(){
      setMyIsLoading(true)
    try {
        const response = await axios.get(`${MYTICKETCOUNT_URL}${getUser()}`)
        const { data } = await response
        const nbPage = Math.ceil(data / 10)
        setNbMyTickets(data)
        setNbMyPage(nbPage)
    } catch (error) {
    }finally{
      setMyIsLoading(false)
    }
    }
    useEffect(() => {
        ticketsCount()
    }, [])
    
    /*
    Des qu'on a récupéré le nb de page on injecte tout les tickets dans myTicketList
    ainsi que dans AllMyTickets et on insere les 10 premiers resultats dans 
    myTicketListPer10
    */
    useEffect(() => {
        async function fetchTickets(){ 
        setMyIsLoading(true)
        try {
            const response = await getAllMyTickets(nbMyTickets, 0, getUser())
            const { data } = await response
            console.log(data)
            setMyTicketList(data)
            setMyTicketListPer10(data.slice(0, 10))
        } catch (error) {
            console.log(error);
        }finally{
            setMyIsLoading(false)
        }
        }
        fetchTickets()
    }, [nbMyTickets])

    useEffect(() => {
      async function fetchTechniciens(){
        try {
          const response = await getTechniciens()
          const { data } = await response
          //Récupère tout les login de techniciens sauf le notre 
          const dataWithoutMe = data.filter((technicien) => technicien?.authIdauth?.login.toLowerCase() !== getUser().toLowerCase())
          setTechniciens(dataWithoutMe)
        } catch (error) {
        }
      }
      fetchTechniciens()
    }, [])

  //Renvoi les données de la page $currentPage
  const fetchNewTickets = (currentPage) => {
    const startIndex = currentPage * ticketPerPage - ticketPerPage;
    const endIndex = startIndex + ticketPerPage;
    return myTicketList.slice(startIndex, endIndex);
  }

  //Modifie les tickets affichés en fonction du numéro de page sélectionné
  const changePage = async (data) => {
    let currentPage = data.selected + 1
    const newTickets = fetchNewTickets(currentPage)

    setMyTicketListPer10(newTickets)
  }

  //Met a jour le nb de page et les tickets 
  function majTicket(tabTicket){
    setMyTicketList(tabTicket)
    setMyTicketListPer10(tabTicket.slice(0,10))
    setNbMyPage(Math.ceil(tabTicket.length / 10))
  }

  return (
    <div>
      <Header />
       {myIsLoading ?    
       <Loader/> :      
       <div className="page-container">
          <div className="tickets-container">    
              <h2>Liste des incidents signalés</h2>
              <ul className="tickets-infos">
              {getOcurrEtat(myTicketList).map(({etat, qtite}, index) => (
                <li key={`${index}-${etat}`}>{etat} : {qtite}</li>
              ))}
              </ul>
              <div className="btn-sort-container">
                <div
                  id="down"
                  onClick={() => majTicket(sortTicketDown(myTicketList))}
                >
                  Décroissant<span>➜</span>
                </div>
                <div
                  id="up"
                  onClick={() => majTicket(sortTicketUp(myTicketList))}
                >
                  Croissant<span>➜</span>
                </div>
              </div>
              <table className='tickets'>
                <HeaderTickets isMyTicket='yes' />
                {myTicketListPer10.map((ticket) => (
                    <Ticket  
                      click={(e)=>{
                        if(e.target.className === 'test'){
                          return
                        }else{
                          navigate(`../detail-ticket/${ticket.idfrdt}`)
                      }
                      }}
                      key={`${ticket.idfrdt}-${ticket.reffrdt}`}
                      isMyTicket='yes'
                      dateApparition={moment.utc(ticket.dateApparition.substring(0, ticket.dateApparition.length-5)).format('DD-MM-YYYY')}
                      dateResolution={ ticket.dateResolution ? moment.utc(ticket.dateResolution.substring(0, ticket.dateResolution.length-5)).format('DD-MM-YYYY') : ''}
                      etat={ticket.etat}
                      idfrdt={ticket.idfrdt}
                      refMateriel={ticket.refMateriel}
                      reffrdt={ticket.reffrdt}
                      resume={ticket.resume}
                      site={ticket.site}
                      techniciens={techniciens}
                      ticketsCount={ticketsCount}
                    />
                    ))}
              </table>
              <ReactPaginate 
                    previousLabel={"< Précédent"}
                    nextLabel={"Suivant >"}
                    breakLabel={"..."}
                    onPageChange={changePage}
                    pageCount={nbMyPage}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={4}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    breakLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    activeClassName={"active"}
              />
          </div>
       </div>    
       }
    </div>
  )
}

export default MyTickets