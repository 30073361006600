import { useState, useContext, useEffect } from "react";
import { saveTicket, getProfilUtilisateur } from './utils'

import SnackbarContext from "../components/store/snackbar-context";


const useFormCreate = (validate) => {
    
    const [values, setValues] = useState({
        site: '',
        user: '',
        materiel: '',
        apparition: '', 
        type: '',
        produit: '',
        urgence: '',
        resume: '',
        description: ''
    })
    
    // On récupère le contexte pour pouvoir modifier les variables qu'il nous envoi
    const snackbarCtx = useContext(SnackbarContext)
    
    const [errors, setErrors] = useState({})

    const [technicienInfos, setTechnicienInfos] = useState({})

    useEffect(() => {
        //Récupère un objet de type : 
        //authIdauth: {actif: true, idAuth: 512, login: 'Alicia', mdp: 'e70103915d0836709ae69e2a9f2f6f57f2e2a433e65e3c84854686ba9f2f7034'}
        async function getDataUser(){ 
        try {
            const response = await getProfilUtilisateur()
            const { data } = await response
            setTechnicienInfos(data.authIdauth)
        } catch (error) {
            console.log(error);
        }
        }
        getDataUser()
    }, [])

    /* A chaque changement de valeur sur un champs on modifie le state en conséquence en
    récupérant son name et sa value */
    const handleChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit =  (e) =>{
        e.preventDefault()
        //errors est un objet contenant tout les champs non rempli
        //isError renvoi la taille de cet objet pour vérifier s'il y a des erreurs
        const {errors, isError} = validate(values)
        setErrors(errors)
        //Affichage de la SNACKBAR avec un message négatif
        if(isError>0){
            const msg = "Un des champs requis n\'est pas remplis";
            snackbarCtx.displayMsg(msg);
        }
        // Création d'un ticket et affichage de la SNACKBAR avec un message positif
        else{
            let fdef = {
                idfrdt: null,
                technicienIdtechnicien: technicienInfos,          
                refMateriel: values.materiel,
                date: new Date().toISOString(),
                dateApparition: new Date(values.apparition).toISOString(),
          
                permanent: values.type === "permanent",
                intermittent: values.type === "intermittent",
                passager: values.type === "passager",
          
                telephone: values.produit === "tel",
                internetReseau: values.produit === "inet",
                pc: values.produit === "pc",
                faxImp: values.produit === "fax",
                autre: values.produit === "autres",
          
                bloquant: values.urgence === "bloquant",
                perturbant: values.urgence === "perturbant",
                genant: values.urgence === "genant",
                aameliorer: values.urgence === "ameliorer",
          
                resume: values.resume,
                description: values.description,
          
                etat: "",
                actionsCollection: []
              }
              console.log(fdef)
              let nomSite = values.site
              saveTicket(values.user ,fdef, nomSite)
            const msg = "Votre ticket a bien été crée";
            snackbarCtx.displayMsg(msg);
            setValues({
                site: '',
                user: '',
                materiel: '',
                apparition: '', 
                type: '',
                produit: '',
                urgence: '',
                resume: '',
                description: ''
            })
        }
    }

    return { handleChange, handleSubmit, values, setValues, errors }
}

export default useFormCreate;

