import Erreur from "./Erreur";
import MyTickets from "./MyTickets";
import TicketList from "./TicketList";
import UserInfo from "./UserInfo";
import TicketCreation from "./TicketCreation";
import TicketDetail from "./TicketDetail";
import { Routes, Route } from 'react-router-dom'

function RouterConnexion() {
  return (
    <Routes>
          <Route path="/mes-tickets" element={<MyTickets />} />
          <Route path="/liste-tickets" element={<TicketList />} />
          <Route path="/creation-ticket" element={<TicketCreation />} />
          <Route path="/mes-informations" element={<UserInfo />} />
          <Route path="/detail-ticket/:id" element={<TicketDetail />} />
          <Route path="*" element={<Erreur />} />
    </Routes>
  )
}

export default RouterConnexion;
